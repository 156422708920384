import { Component, Input, OnDestroy, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { zip } from 'rxjs';
import { ConfigService } from '../shared/services/config.service';
import { MsgKeyModule } from '../shared/translate/msg-key.module';

interface VidflarePreview {
	on(event: string, callback: Function): void;

	prepareImage(imgUrl: string): Promise<any>;

	goToFrame(frame: number): void;

	setDynamicData(data: any): void;
}

declare global {
	interface Window {
		createVidflarePreview(config): VidflarePreview;
	}
}

const VIDFLARE_LOADER_ID = 'vidflare-loader';

@Component({
	templateUrl: './video-preview.component.html',
	styleUrls: ['./video-preview.component.less'],
	encapsulation: ViewEncapsulation.None,
	providers: [],
	standalone: true,
	imports: [
		MsgKeyModule,
	],
})
export class VideoPreviewComponent implements OnInit, OnDestroy {

	@Input({ required: true })
	projectid: string;
	@Input({ required: true })
	layoutid: string;

	private _modifications: Record<string, string>;
	@Input({ required: true })
	set modifications(mods: string) {
		this._modifications = JSON.parse(mods);
	}

	vidflarePreviewObj: any;

	constructor(private configService: ConfigService, private renderer: Renderer2) {
		zip(this.configService.getVidflareUrl(), this.configService.getVidflareAccessToken())
			.subscribe(([url, accessToken]) => this.addJsToHead(url, accessToken));
	}

	ngOnInit(): void {
		document.removeAllListeners('vidflareReadyToBeInitialized');
		document.addEventListener('vidflareReadyToBeInitialized', () => {

			// create vidflare preview instance
			this.vidflarePreviewObj = window.createVidflarePreview({
				el: '#vidflare-loading',
				vidflareProps: {
					projectUuid: this.projectid,
					layoutUuid: this.layoutid,
					// relevantFrames: [
					// 	100,
					// 	600,
					// ],
				},
				token: document.getElementById(VIDFLARE_LOADER_ID)
					.getAttribute('data-token'),
			});

			// triggered once the plugin loaded successfully
			this.vidflarePreviewObj.on('loaded', () => {
				this.changeData({
					...this._modifications,
					Agency_Photo: undefined,
				});
				if (!this._modifications.Agency_Photo?.length) {
					return;
				}
				this.vidflarePreviewObj.prepareImage(this._modifications.Agency_Photo)
					.then(res => {
						this.changeData({
							...this._modifications,
							Agency_Photo: res,
						});
					});
			});
		});
	}

	ngOnDestroy() {
		this.vidflarePreviewObj.remove();
	}

	private changeData(data) {
		// this.vidflarePreviewObj.goToFrame(600);
		this.vidflarePreviewObj.setDynamicData(data);
	}

	private addJsToHead(src: string, accessToken: string) {
		const script = document.createElement('script');
		script.type = 'text/javascript';
		script.id = VIDFLARE_LOADER_ID;
		script.src = src + '/standalone/loader';
		script.setAttribute('data-token', accessToken);
		this.renderer.appendChild(document.head, script);
	}
}