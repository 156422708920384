import { ChangeDetectionStrategy, Component, Injector, OnInit, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { createCustomElement } from '@angular/elements';
import { Router, RoutesRecognized } from '@angular/router';
import { createCustomElements } from '@bas/ui';
import { Store } from '@ngrx/store';
import { switchMap } from 'rxjs/operators';
import { LoggingService } from './logging/logging.service';
import { NavigationQuery } from './navigation/store/navigation.reducer';
import { UserService } from './permissions/user/user.service';
import { LocaleService } from './shared/services/locale.service';
import { IsMobileAction, IsNotMobileAction } from './shared/store/actions/mobile.actions';
import { ApplicationState } from './shared/store/application-state';
import { Logout } from './shared/store/logout.action';
import { UserQuery } from './shared/store/reducers/user.reducer';
import { VideoPreviewComponent } from './video-preview/video-preview.component';

@Component({
	selector: 'bas-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {

	isMobile: boolean;

	showSideNav = this.store.selectSignal(NavigationQuery.showSideNav);
	showMainNav = this.store.selectSignal(NavigationQuery.showMainNav);
	showNavHeader = this.store.selectSignal(NavigationQuery.showNavHeader);
	showMobileNav = this.store.selectSignal(NavigationQuery.showMobileNav);
	showNavFooter = this.store.selectSignal(NavigationQuery.showNavFooter);
	canSeeDebugLinks = toSignal(this.store.select(UserQuery.getUser)
		.pipe(switchMap(() => this.userService.has('debug'))), { initialValue: false });
	isLoginPage = signal(true);

	constructor(
		private store: Store<ApplicationState>,
		private userService: UserService,
		private router: Router,
		private localeService: LocaleService,
		injector: Injector,
		loggingService: LoggingService,
	) {
		loggingService.log({ 'event': 'Angular Anwendung initialisiert.' }, 'general');
		createCustomElements(injector);
		customElements.define('bwc-video-preview', createCustomElement(VideoPreviewComponent, { injector }));
	}

	ngOnInit(): void {
		this.updateIsMobile();
		this.localeService.setLocale('de_DE');
	}

	private updateIsMobile() {
		this.isMobile = navigator.userAgent.includes('Mobi');
		if (this.isMobile) {
			this.store.dispatch(new IsMobileAction());
			this.updateIsLoginPage();
			if (!window.location.pathname.includes('mobile')) {
				this.router.navigateByUrl('/logout.do');
				this.store.dispatch(new Logout);
			}
		} else {
			this.store.dispatch(new IsNotMobileAction());
			if (window.location.pathname.includes('mobile')) {
				this.router.navigateByUrl('/logout.do');
				this.store.dispatch(new Logout);
			}
		}
	}

	private updateIsLoginPage() {
		this.router.events.subscribe(event => {
			if (event instanceof RoutesRecognized) {
				this.isLoginPage.set(['start.do', 'login.do', 'logout.do'].some(route => event.state.url.includes(route)));
			}
		});
	}

	scrollToTop() {
		window.scrollTo(0, 0);
	}
}
